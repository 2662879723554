import "./experience.scss";
import Atlassian from "../assets/atlassianIconSmall.png";
import INRIX from "../assets/inrixIconSmall.png";
import Panterix from "../assets/panterixIconSmall.png";

const experiences = [
  {
    icon: Atlassian,
    title: "Atlassian - Software Development Engineer Intern",
    description: "June 2022 - September 2022",
    key: 0,
  },
  {
    icon: INRIX,
    title: "INRIX - Full-Stack Software Development Engineer Intern",
    description: "June 2021 - September 2021",
    key: 1,
  },
  {
    icon: Panterix,
    title: "Panterix LLC - Co-Founder and Software Development Engineer",
    description: "February 2020 - Present",
    key: 2,
  },
];

function Experience() {
  return (
    <div className="experience-container">
      <div className="experience-header">Notable Experiences</div>
      <div className="experience-list">
        {experiences.map((experience) => (
          <div className="experience" key={experience.key}>
            <div className="experience-title">
              <img
                className="experience-icon"
                src={experience.icon}
                alt="Logo"
              />
              <div className="experience-title-text">{experience.title}</div>
            </div>
            <div className="experience-description">
              {experience.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Experience;

import "./projects.scss";
import Card from "./card.js";
import { forwardRef } from "react";

const projects = [
	{
		title: "Quick Bites",
		description:
			"Website for Hackathon that empowers low-income households to find low cost meal recipes. User interface in Vue.js, and databse in Azure Cosmos.",
		buttonTitle: "DevPost",
		buttonLink: "https://devpost.com/software/quick-bites",
		large: true,
		color: "#FCF55F",
	},
	{
		title: "Instagram Captions",
		description:
			"Caption generation tool which utilizes web scraping to identify trend worthy captions. Built in React.js and Java Spring.",
		buttonTitle: "Website",
		buttonLink: "http://igcaptions.online/",
		large: false,
		color: "#d1504d",
	},
	{
		title: "Airdometer",
		description:
			"Website which uses Breezometer APIs to identify and analyze air quality trends. Built using the MERN stack.",
		buttonTitle: "Github",
		buttonLink: "https://github.com/kevinrooney/Air-Quality-UI",
		large: false,
		color: "#b79fd8",
	},
	{
		title: "SCU ACM",
		description:
			"Website for SCU's chapter of ACM. Built from scratch in Vue.js and Google APIs as the standing Webmaster.",
		buttonTitle: "Website",
		buttonLink: "http://acm.engr.scu.edu/#/",
		large: true,
		color: "#007fff",
	},
	{
		title: "Panterix",
		description:
			"Business which evaluates roads on their safety globally by using a multitude of historical and real-time factors.",
		buttonTitle: "Website",
		buttonLink: "http://Panterix.com",
		large: false,
		color: "#82faa2",
	},
	{
		title: "Dangerous Roads",
		description:
			"Website developed while working at INRIX which gives insights on the most dangerous roads globally.",
		buttonTitle: "Website",
		buttonLink: "https://inrix.com/most-dangerous-roads/",
		large: false,
		color: "#FFA500",
	},
];

const Projects = forwardRef((props, ref) => {
	return (
		<div className="projects-container" ref={ref}>
			<div className="projects-header">Projects</div>
			<div className="card-container">
				{projects.map((project) => (
					<Card
						large={project.large}
						title={project.title}
						description={project.description}
						color={project.color}
						buttonTitle={project.buttonTitle}
						buttonLink={project.buttonLink}
						key={project.title}
					/>
				))}
			</div>
		</div>
	);
});

export default Projects;

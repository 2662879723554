import "./card.scss";

const openLink = (link) => {
  window.open(link, "_blank");
};

function Card(props) {
  return (
    <div className={props.large ? "card-large" : "card"}>
      <div
        className="color-stripe"
        style={{ backgroundColor: props.color }}
      ></div>
      <div className="card-content-container">
        <div className="card-title">{props.title}</div>
        <div className={props.large ? "card-info-large" : "card-info"}>
          {props.description}
        </div>
        <div
          className="card-button"
          onClick={openLink.bind(this, props.buttonLink)}
        >
          {props.buttonTitle}
        </div>
      </div>
    </div>
  );
}

export default Card;

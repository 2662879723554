import "./footer.scss";
import { FaLinkedin, FaDev, FaGithub } from "react-icons/fa";

const socials = [
	{
		icon: FaLinkedin,
		link: "https://www.linkedin.com/in/kevintrooney/",
		key: 0,
	},
	{
		icon: FaDev,
		link: "https://devpost.com/krooney?ref_content=user-portfolio&ref_feature=portfolio&ref_medium=global-nav",
		key: 1,
	},
	{
		icon: FaGithub,
		link: "https://github.com/kevinrooney",
		key: 2,
	},
];

const openLink = (link) => {
	window.open(link, "_blank");
};

function Footer() {
	return (
		<div className="footer">
			<div className="contact-container">
				<div className="contact-title">What are you working on?</div>
				<div className="contact-subtitle">
					Let's have a conversation! I'd love to hear about what you're working
					on and find a way to work together.
				</div>
				<a className="contact-btn" href={"mailto:krooney@scu.edu"}>
					chat
				</a>
			</div>
			<div className="footer-lower">
				<div className="footer-container">
					<div className="footer-content-container">
						<div className="footer-content">
							Designed and developed by Kevin Rooney.
						</div>
						<div className="footer-content">
							Built with <p className="footer-content-light">React.js. </p>
							Hosted on <p className="footer-content-light">Vercel. </p>Icons by{" "}
							<p className="footer-content-light">React Icons.</p>
						</div>
					</div>
					<div className="footer-socials">
						{socials.map((social) => (
							<div
								className="social"
								onClick={openLink.bind(this, social.link)}
								key={social.key}
							>
								<social.icon style={{ width: "25px", height: "25px" }} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;

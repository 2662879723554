import "./header.scss";

function Header() {
	return (
		<div className="header-container">
			<div className="header-title">
				I'm Kevin, a software engineer and student at Santa Clara University.
			</div>
			<div className="header-subtitle">
				I do full-stack development and love working with distributed systems.
				Feel free to reach out to me on{" "}
				<a
					href="https://www.linkedin.com/in/kevintrooney/"
					style={{ color: "#82faa2", textDecoration: "underline" }}
				>
					LinkedIn
				</a>
				, or check out my{" "}
				<a
					href="https://github.com/kevinrooney"
					style={{ color: "#82faa2", textDecoration: "underline" }}
				>
					Github
				</a>
				!
			</div>
		</div>
	);
}

export default Header;

import "./navbar.scss";
import logo from "../assets/logo.svg";

function Navbar(props) {
	return (
		<div className="navbar">
			<div className="navbar-container">
				<div className="logo-container">
					<img className="logo" src={logo} alt="Logo" />
					<div className="logo-title">Kevin Rooney</div>
				</div>
				<div className="links-container">
					{/* <div className="link">About</div> */}
					<div className="link" onClick={props.executeScroll}>
						Portfolio
					</div>
					<a className="link link-btn" href={"mailto:krooney@scu.edu"}>
						Contact
					</a>
				</div>
			</div>
		</div>
	);
}

export default Navbar;

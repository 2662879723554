import "./App.scss";
import Navbar from "./layout/navbar.js";
import Header from "./components/header.js";
import Experience from "./components/experience.js";
import Projects from "./components/projects.js";
import Footer from "./layout/footer.js";

import React, { useRef } from "react";

function App() {
	const projects = useRef(null);

	const executeScroll = () => {
		if (!projects.current) return;
		projects.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<div>
			<Navbar executeScroll={executeScroll} />
			<Header />
			<Experience />
			<Projects ref={projects} />
			<Footer />
		</div>
	);
}

export default App;
